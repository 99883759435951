<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading" rounded="sm">
            <AccountLevelsTable :items="items" :columns="columns"/>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BCard,
    BOverlay
} from 'bootstrap-vue'
import AccountLevelsTable from "@/views/AccountLevels/AccountLevelsTable";

export default {
    name: "AccountLevels",
    components: {
        AccountLevelsTable,
        BCard,
        BOverlay
    },
    data: () => ({
        dir: false,
        loading: false,
        coins: [],
        items: [],
        data: {},
        edit: false,
        type: false,
        columns: [
            {
                label: 'نام سطح',
                key: 'userAccountLevel',
                sortable: false,
            },
            {
                label: 'کارمزد تومانی',
                key: 'wagePercent',
                sortable: false,
            },
            {
                label: 'کارمزد تتری',
                key: 'wagePercentTether',
                sortable: false,
            },
            {
                label: 'حداکثر مقدار برداشت کریپتو',
                key: 'cryptoWithdrawLimit',
                sortable: false,
            },
            {
                label: 'حداکثر مقدار برداشت تومانی',
                key: 'tomanWithdrawLimit',
                sortable: false,
            },
            {
                label: 'حداکثر مقدار واریز تومانی',
                key: 'tomanDepositLimit',
                sortable: false,
            },
            {
                label: 'مقدار تا سطح بعد',
                key: 'tradeAmount',
                sortable: false,
            },
        ],
    }),
    methods: {
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/account-levels')
            this.items = res.data
        },
    },
    async created() {
        await this.getData()
    }
}
</script>

<style scoped>

</style>