var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refUserListTable",staticClass:"text-nowrap",staticStyle:{"min-height":"235px"},attrs:{"items":_vm.items,"small":true,"responsive":"","striped":"","fields":_vm.columns,"primary-key":"id","show-empty":"","empty-text":"اطلاعاتی یافت نشد"},scopedSlots:_vm._u([{key:"cell(userAccountLevel)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('span',[_vm._v(_vm._s(item.userAccountLevel))]):_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(item.userAccountLevel))])]}},{key:"cell(wagePercent)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"wagePercent","label":"کارمزد تومانی"}}):_c('span',[_vm._v("0")])]}},{key:"cell(wagePercentTether)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"wagePercentTether","label":"کارمزد تتری"}}):_c('span',[_vm._v("0")])]}},{key:"cell(tradeAmount)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR','PLATINUM'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"tradeAmount","label":"مقدار تا سطح بعد"}}):_c('span',[_vm._v("∞")])]}},{key:"cell(cryptoWithdrawLimit)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"cryptoWithdrawLimit","label":"حداکثر مقدار برداشت کریپتو"}}):_vm._e()]}},{key:"cell(tomanWithdrawLimit)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"tomanWithdrawLimit","label":"حداکثر مقدار برداشت تومانی"}}):_vm._e()]}},{key:"cell(tomanDepositLimit)",fn:function(ref){
var item = ref.item;
return [(!['SUPERVISOR'].includes(item.userAccountLevel))?_c('AccountLevelTableField',{attrs:{"item":item,"target":"tomanDepositLimit","label":"حداکثر مقدار واریز تومانی"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }